import { lazy, FC, LazyExoticComponent } from 'react';

export type IconKeys =
  | 'homeIcon'
  | 'moveMoneyIcon'
  | 'rewardsIcon'
  | 'rewardsTrophyIcon'
  | 'accountIcon'
  | 'paypalTransfersIcon'
  | 'fuelperksIcon'
  | 'cashbackIcon';

export const bottomBarIcons: Record<
  IconKeys,
  LazyExoticComponent<FC<{ color: string }>>
> = {
  homeIcon: lazy(() => import('./home-icon')),
  moveMoneyIcon: lazy(() => import('./move-money-icon')),
  rewardsIcon: lazy(() => import('./rewards-icon')),
  rewardsTrophyIcon: lazy(() => import('./rewards-trophy-icon')),
  accountIcon: lazy(() => import('./account-icon')),
  paypalTransfersIcon: lazy(() => import('./paypal-transfers-icon')),
  fuelperksIcon: lazy(() => import('./fuelperks-icon')),
  cashbackIcon: lazy(() => import('./cashback-icon'))
};
